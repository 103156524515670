import i18n from "../../../../Utils/i18next/i18next";
import store from "../../../../store";
import { NotificationManager } from "react-notifications";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { cloneDeep } from "lodash";

const { t } = i18n;

export const onSubmit = async (values, props) => {
  const { printerModelReducer } = store.getState();
  const { updateTableRegistry, loadData, update, updateSingleRegistry, uuid } =
    props;

  const data = cloneDeep({
    ...values,
    competenceIndicator: values?.competenceIndicator?.value,
    options: values?.options?.map((el) => ({ uuid: el.value })),
  });

  let payload;

  if (!update) {
    if (!printerModelReducer?.availableStatuses) {
      const { payload } = await GlobalAuthFetch({
        path: `/registry/getByUrl/printermodel`,
      });
      data.status = payload.data.availableStatuses.find(
        (el) => !!el.isSelected
      );
    } else {
      data.status = printerModelReducer?.availableStatuses.find(
        (el) => !!el.isSelected
      );
    }

    const res = await GlobalAuthFetch({
      path: `/printermodel`,
      method: "POST",
      data,
    });
    payload = res.payload;
  }

  if (update) {
    const res = await GlobalAuthFetch({
      path: `/printermodel/${uuid}`,
      method: "PATCH",
      data,
    });
    payload = res.payload;
  }

  if (payload?.status >= 300 || !payload?.status) {
    props.setErrorHeader(0, true);

    return;
  }

  props.setErrorHeader(0, false);

  //Succes nofify
  loadData();
  NotificationManager.success(i18n.t("global:success"));
  if (updateSingleRegistry) updateSingleRegistry();
  if (updateTableRegistry) updateTableRegistry();
  props.handleHide();
};

export const onValidate = (values, props) => {
  // const { update } = props;
  const errorsStep0 = {};

  if (!values.modelName) {
    errorsStep0.modelName = t("global.printerModel.errors.modelName");
  }

  if (!values.manufacturer) {
    errorsStep0.manufacturer = t("global.printerModel.errors.manufacturer");
  }

  if (!values.deviceType) {
    errorsStep0.deviceType = t("global.printerModel.errors.deviceType");
  }
  if (
    !props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length > 0
  ) {
    props.setErrorHeader(0, true);
  }
  if (
    !!props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length <= 0
  ) {
    props.setErrorHeader(0, false);
  }

  return { ...errorsStep0 };
};
