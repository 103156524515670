import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  manager: null,
  defaultFileStatus: null,
  defaultFolders: null,
  availableStatuses: null,
  registryRights: [],
  table: {
    search: "",
    limit: 11,
    page: 1,
    pages: 1,
    sort: "",
    data: [],
    searchFields: [
      { key: "printerModel.modelName", name: "global:device.model" },
      { key: "serviceBase.entryName", name: "global:device.serviceBase" },
      { key: "serialNumber", name: "global:device.serialNumber" },
      { key: "client.name", name: "global:device.client" },
      { key: "branch.name", name: "global:device.branch" },
      { key: "status.name", name: "global:device.status" },
    ],
    selectedSearchFields: [
      "printerModel.modelName",
      "serviceBase.entryName",
      "serialNumber",
    ],
    dictionaryFields: ["serviceBase", "servicePriority", "deviceOwner"],
    url: "device",
  },
};

const deviceReducer = createSlice({
  name: "deviceReducer",
  initialState,
  reducers: {
    setRegistryRights: (state, { payload }) => {
      state.registryRights = payload;
    },
    updateManager: (state, { payload }) => {
      state.manager = payload;
    },
    updateDefaultFileStatus: (state, { payload }) => {
      state.defaultFileStatus = payload;
    },
    updateDefaultFolders: (state, { payload }) => {
      state.defaultFolders = payload;
    },
    updateAvailableStatuses: (state, { payload }) => {
      state.availableStatuses = payload;
    },
    updateRegistry: (state, { payload }) => {
      state.table = { ...state.table, ...payload };
    },
    clearRegistry: () => initialState,
  },
  extraReducers: {},
});

export const {
  updateRegistry,
  clearRegistry,
  updateManager,
  updateDefaultFileStatus,
  updateDefaultFolders,
  updateAvailableStatuses,
  setRegistryRights,
} = deviceReducer.actions;

export default deviceReducer.reducer;
