import i18n from "../../../../Utils/i18next/i18next";
import store from "../../../../store";
import { NotificationManager } from "react-notifications";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { cloneDeep, set } from "lodash";

const { t } = i18n;

export const onSubmit = async (values, props) => {
  const { deviceReducer } = store.getState();
  const { updateTableRegistry, loadData, update, updateSingleRegistry, uuid } =
    props;
  const data = cloneDeep(values);

  if (values?.printerModel?.value)
    set(data, "printerModel.uuid", values?.printerModel?.value);

  if (values?.serviceBase?.value)
    set(data, "serviceBase.uuid", values?.serviceBase?.value);

  if (values?.servicePriority?.value) {
    set(data, "servicePriority.uuid", values?.servicePriority?.value);
  }
  if (values?.supplier?.value) {
    set(data, "supplier.uuid", values?.supplier?.value);
  }
  if (values?.deviceOwner?.value) {
    set(data, "deviceOwner.uuid", values?.deviceOwner?.value);
  }

  data.deviceOptions = values?.deviceOptions?.map((el) => ({
    uuid: el.value,
  }));

  if (values?.client?.value) set(data, "client.uuid", values?.client?.value);
  if (values?.branchAddress?.value)
    set(data, "branchAddress.uuid", values?.branchAddress?.value);
  if (values?.clientRepresentative?.value)
    set(data, "clientRepresentative.uuid", values?.clientRepresentative?.value);

  let payload;

  if (!update) {
    data.status = deviceReducer?.availableStatuses.find(
      (el) => !!el.isSelected
    );

    const res = await GlobalAuthFetch({
      path: `/device`,
      method: "POST",
      data,
    });
    payload = res.payload;
  }

  if (update) {
    const res = await GlobalAuthFetch({
      path: `/device/${uuid}`,
      method: "PATCH",
      data,
    });
    payload = res.payload;
  }
  if (payload?.status >= 300 || !payload?.status) {
    props.setErrorHeader(0, true);
    props.setErrorHeader(1, true);

    return;
  }

  props.setErrorHeader(0, false);
  props.setErrorHeader(1, false);

  //Succes nofify
  loadData();
  NotificationManager.success(i18n.t("global:success"));
  if (updateSingleRegistry) updateSingleRegistry();
  if (updateTableRegistry) updateTableRegistry();
  props.handleHide();
};

export const onValidate = (values, props) => {
  const errorsStep0 = {};
  const errorsStep1 = {};

  if (!values.serialNumber) {
    errorsStep0.serialNumber = t("global:device.errors.serialNumber");
  }
  if (!values.printerModel) {
    errorsStep0.printerModel = t("global:device.errors.printerModel");
  }

  if (!values.serviceBase) {
    errorsStep0.serviceBase = t("global:device.errors.serviceBase");
  }
  if (!values.client) {
    errorsStep0.client = t("global:device.errors.client");
  }
  if (!values.client) {
    errorsStep0.client = t("global:device.errors.client");
  }
  if (!values.branch) {
    errorsStep0.branch = t("global:device.errors.branch");
  }

  if (
    !props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length > 0
  ) {
    props.setErrorHeader(0, true);
  }
  if (
    !!props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length <= 0
  ) {
    props.setErrorHeader(0, false);
  }
  if (
    !props.steps[1]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep1).length > 0
  ) {
    props.setErrorHeader(1, true);
  }
  if (
    !!props.steps[1]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep1).length <= 0
  ) {
    props.setErrorHeader(1, false);
  }
  return { ...errorsStep0, ...errorsStep1 };
};
