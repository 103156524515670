import { Form } from "react-final-form";
import { onSubmit, onValidate } from "./DeviceFormSubmit";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@emotion/react";
import DeviceFormStepOne from "./DeviceFormSteps/DeviceFormStepOne";
import DeviceFormStepTwo from "./DeviceFormSteps/DeviceFormStepTwo";
import { useEffect, useState } from "react";

const DeviceForm = (props) => {
  const theme = useTheme();
  const { step, setStep, update, initialValues: _initialValues } = props;
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    if (update) {
      setInitialValues({
        ..._initialValues,
        deviceOwner: _initialValues?.deviceOwner?.uuid
          ? { value: _initialValues?.deviceOwner?.uuid }
          : null,
        serviceBase: _initialValues?.serviceBase?.uuid
          ? { value: _initialValues?.serviceBase?.uuid }
          : null,
        servicePriority: _initialValues?.servicePriority?.uuid
          ? { value: _initialValues?.servicePriority?.uuid }
          : null,
        deviceOptions: _initialValues?.deviceOptions?.uuid
          ? { value: _initialValues?.deviceOptions?.uuid }
          : null,

        printerModel: _initialValues?.printerModel?.uuid
          ? {
              ..._initialValues?.printerModel,
              label: _initialValues?.printerModel?.modelName,
              uuid: _initialValues?.printerModel?.uuid,
            }
          : null,
        client: _initialValues?.client?.uuid
          ? {
              ..._initialValues?.client,
              label: _initialValues?.client?.name,
              uuid: _initialValues?.client?.uuid,
            }
          : null,
        branch: _initialValues?.branch?.uuid
          ? {
              ..._initialValues?.branch,
              label: _initialValues?.branch?.name,
              uuid: _initialValues?.branch?.uuid,
            }
          : null,
        supplier: _initialValues?.supplier?.uuid
          ? {
              ..._initialValues?.supplier,
              label: _initialValues?.supplier?.name,
              uuid: _initialValues?.supplier?.uuid,
            }
          : null,
        clientRepresentative: _initialValues?.clientRepresentative?.uuid
          ? {
              ..._initialValues?.clientRepresentative,
              label: `${_initialValues?.clientRepresentative?.name} ${_initialValues?.clientRepresentative?.lastname}`,
              uuid: _initialValues?.clientRepresentative?.uuid,
            }
          : null,
      });
      return;
    }
    setInitialValues({});
    // eslint-disable-next-line
  }, []);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={(val) => onSubmit(val, props)}
      validate={(val) => onValidate(val, props)}
      validateOnBlur={true}
    >
      {(formProps) => {
        return (
          <form
            id={props.formName}
            onSubmit={formProps.handleSubmit}
            className="DeviceForm"
          >
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={step}
              onChangeIndex={(index) => setStep(index)}
            >
              <DeviceFormStepOne {...formProps} {...props} />
              <DeviceFormStepTwo {...formProps} {...props} />
            </SwipeableViews>
          </form>
        );
      }}
    </Form>
  );
};

export default DeviceForm;
