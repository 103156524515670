import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { submit, validate } from "./SecretariatCorespondenceContactFormSubmit";
import { Col, Row } from "react-bootstrap";
import "./SecretariatCorespondenceContactForm.scss";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { updateDashboardByName } from "../../../../Utils/Sockets";
import { useDispatch } from "react-redux";
import { show } from "redux-modal";
import BMXTextField from "../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXAutocomplente from "../../../../Components/Inputs/BMXAutocomplete/BMXAutocomplete";

const SecretariatCorespondenceContactForm = (props) => {
  const { contactValues: initialValues, formName, subject } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [contacts, setContacts] = useState([]);
  const REGISTRY_NAME = "contact";

  const loadData = async () => {
    const fetchContact = GlobalAuthFetch({ path: "/contact/all" });
    const [resContact] = await Promise.all([fetchContact]);
    setContacts(
      resContact?.payload?.data?.map(
        (el) =>
          ({
            uuid: el.uuid,
            label: el.name,
            ...el,
          } || [])
      )
    );
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const getSelectedContact = (contact) => {
    if (!contact) return null;
    return contacts.find(
      (el) => el.uuid === contact.value || el.uuid === contact.uuid
    );
  };

  return (
    <Form
      initialValues={{
        ...initialValues,
        contact: initialValues?.contact ? {
          ...initialValues?.contact,
          label: initialValues?.contact?.name,
          uuid: initialValues?.contact?.uuid,
        }:null,
        branch: initialValues?.branch ? {
          ...initialValues?.branch,
          label: initialValues?.branch?.name,
          uuid: initialValues?.branch?.uuid,
        }: null,
        representative: initialValues?.representative ? {
          ...initialValues?.representative,
          label: `${initialValues?.representative?.name || " "} ${
            initialValues?.representative?.lastname || " "
          }`,
          uuid: initialValues?.representative?.uuid,
        }:null,
        subject: subject,
      }}
      onSubmit={(val) => submit(val, { ...props, contacts: contacts })}
      validate={validate}
    >
      {({ handleSubmit, values }) => {
        return (
          <form
            onSubmit={handleSubmit}
            className="SecretariatCorespondenceContactForm justify-content-around"
            id={formName}
          >
            <Row>
              <Col xs={10}>
                <Field
                  component={BMXAutocomplente}
                  label={`${t(
                    "global:secretariatCorespondenceContact.contact"
                  )}`}
                  name="contact"
                  data-cy="transferToPerson"
                  options={contacts?.sort((a,b)=>a?.name?.localeCompare(b?.name))}
                  currentValues={values}
                  afterIcon="add"
                  afterIconSize="13px"
                  afterIconClick={() => {
                    const updateTableRegistry = () =>
                      updateDashboardByName(REGISTRY_NAME);
                    dispatch(
                      show("ContactsModal", {
                        updateTableRegistry,
                        header: t("global:contacts.add"),
                        loadData,
                      })
                    );
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={10}>
                <Field
                  data-cy="branch"
                  beforeIcon="domain"
                  afterIcon="add"
                  afterIconSize="13px"
                  afterIconClick={() => {
                    const contactUuid =
                      values?.contact?.uuid || values?.contact?.value;
                    const updateSingleRegistry = () =>
                      updateDashboardByName(REGISTRY_NAME, contactUuid);
                    dispatch(
                      show("BranchesModal", {
                        loadData,
                        header: t("global:contact.branchAdd"),
                        updateSingleRegistry,
                        uuid: contactUuid,
                      })
                    );
                  }}
                  disabled={!values?.contact}
                  currentValues={values}
                  component={BMXAutocomplente}
                  options={
                    getSelectedContact(values?.contact)?.branches?.sort((a,b)=>a?.name?.localeCompare(b?.name))?.map(
                      (el) => ({ uuid: el.uuid, label: `${el.name || ""}` })
                    ) || []
                  }
                  label={`${t(
                    "global:secretariatCorespondenceContact.branch"
                  )}`}
                  name="branch"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={10}>
                <Field
                  data-cy="representative"
                  beforeIcon="people"
                  afterIcon="add"
                  afterIconSize="13px"
                  afterIconClick={() => {
                    const contactUuid =
                      values?.contact?.uuid || values?.contact?.value;
                    const updateSingleRegistry = () =>
                      updateDashboardByName(REGISTRY_NAME, contactUuid);
                    dispatch(
                      show("RepresentativesModal", {
                        loadData,
                        header: t("global:contact.representativeAdd"),
                        updateSingleRegistry,
                        uuid: contactUuid,
                      })
                    );
                  }}
                  currentValues={values}
                  disabled={!values?.contact}
                  component={BMXAutocomplente}
                  options={
                    getSelectedContact(values?.contact)?.representatives?.sort((a,b)=>a?.lastname?.localeCompare(b?.lastname))?.map(
                      (el) => ({
                        uuid: el.uuid,
                        label: `${el.name || ""} ${el.lastname || ""}`,
                      })
                    ) || []
                  }
                  label={`${t(
                    "global:secretariatCorespondenceContact.representative"
                  )}`}
                  name="representative"                 
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Field
                  data-cy="subject"
                  component={BMXTextField}
                  label={`${t("global:secreateryCorespondence.subjectv2")}`}
                  name="subject"
                />
              </Col>
            </Row>
          </form>
        );
      }}
    </Form>
  );
};

export default SecretariatCorespondenceContactForm;
